import React from 'react';
import {Link} from "react-router-dom";
import logo from '../../assets/images/logo-white.png'

function Footer(props) {
      return (
            <footer className='footer'>
                  <svg id="wave" style={{transform: 'rotate(0deg)', transition: '0.3s'}} viewBox="0 0 1440 210" version="1.1" xmlns="http://www.w3.org/2000/svg"><defs><linearGradient id="sw-gradient-0" x1={0} x2={0} y1={1} y2={0}><stop stopColor="rgba(204, 76, 209, 1)" offset="0%" /><stop stopColor="rgba(204, 76, 209, 1)" offset="100%" /></linearGradient></defs><path style={{transform: 'translate(0, 0px)', opacity: 1}} fill="url(#sw-gradient-0)" d="M0,0L60,28C120,56,240,112,360,136.5C480,161,600,154,720,126C840,98,960,49,1080,49C1200,49,1320,98,1440,112C1560,126,1680,105,1800,91C1920,77,2040,70,2160,87.5C2280,105,2400,147,2520,140C2640,133,2760,77,2880,63C3000,49,3120,77,3240,101.5C3360,126,3480,147,3600,133C3720,119,3840,70,3960,52.5C4080,35,4200,49,4320,77C4440,105,4560,147,4680,136.5C4800,126,4920,63,5040,38.5C5160,14,5280,28,5400,31.5C5520,35,5640,28,5760,38.5C5880,49,6000,77,6120,91C6240,105,6360,105,6480,91C6600,77,6720,49,6840,59.5C6960,70,7080,119,7200,133C7320,147,7440,126,7560,129.5C7680,133,7800,161,7920,164.5C8040,168,8160,147,8280,115.5C8400,84,8520,42,8580,21L8640,0L8640,210L8580,210C8520,210,8400,210,8280,210C8160,210,8040,210,7920,210C7800,210,7680,210,7560,210C7440,210,7320,210,7200,210C7080,210,6960,210,6840,210C6720,210,6600,210,6480,210C6360,210,6240,210,6120,210C6000,210,5880,210,5760,210C5640,210,5520,210,5400,210C5280,210,5160,210,5040,210C4920,210,4800,210,4680,210C4560,210,4440,210,4320,210C4200,210,4080,210,3960,210C3840,210,3720,210,3600,210C3480,210,3360,210,3240,210C3120,210,3000,210,2880,210C2760,210,2640,210,2520,210C2400,210,2280,210,2160,210C2040,210,1920,210,1800,210C1680,210,1560,210,1440,210C1320,210,1200,210,1080,210C960,210,840,210,720,210C600,210,480,210,360,210C240,210,120,210,60,210L0,210Z" /></svg>
                  <div className="circle second"></div>
                  <div className="wp-inner">
                        <div className="top">
                              <div className="logo">
                                    <Link to='/' className='thumb'>
                                          <img src={logo} alt=""/>
                                    </Link>
                              </div>
                              <div>
                                    <h4 className="title">Công ty Kantek</h4>
                                    <ul>
                                          <li>
                                                <Link to='/'>Về chúng tôi</Link>
                                          </li>
                                          <li>
                                                <Link to='/instruction'>Hỗ trợ</Link>
                                          </li>
                                    </ul>
                              </div>
                              <div>
                                    <h4 className="title">Hỗ trợ khách hàng</h4>
                                    <ul>
                                          <li>Tư vấn: (518) 805-1844</li>
                                          <li>vietnails.us@gmail.com</li>
                                          <li>https://www.facebook.com/123vietnails</li>
                                    </ul>
                              </div>
                              <div>
                                    <h4 className="title">Giải pháp</h4>
                                    <ul>
                                          <li>Phần mềm đặt lịch hẹn làm nails</li>
                                          <li>Phần mềm quản lí salon nails</li>
                                          <li>Phần mềm đăng tin miễn phí</li>
                                    </ul>
                              </div>
                        </div>
                        <div className="bottom">
                              <p>Việt Nails là phần mềm quản lý chuyên nghiệp nhất được tin dùng bởi hàng ngàn chủ doanh nghiệp Spa, Nail, Hair, Beauty Salon,.. nhờ tính chuyên nghiệp, đơn giản, thân thiện, dễ sử dụng nhưng đầy hiệu quả. Phần mềm đưa ra hàng loạt giải pháp thông minh, tập trung vào đúng quy trình nghiệp vụ, phù hợp với từng loại hình doanh nghiệp về làm đẹp và thẩm mỹ.</p>
                        </div>
                  </div>
            </footer>
      );
}

export default Footer;