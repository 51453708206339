import React from 'react';
import tabImageFirst from '../../../../assets/images/customer/1.png'
import tabImageTwo from '../../../../assets/images/customer/2.png'
import tabImageThree from '../../../../assets/images/customer/3.png';

function CustomerSection(props) {
      return (
            <section className='customer-section'>
                  <div className="wp-inner">
                        <h4 className="title-section">Nâng cao chất lượng dịch vụ chăm sóc khách hàng</h4>

                        <ul>
                              <li className="item">
                                    <div className="thumb">
                                          <img src={tabImageFirst} alt=""/>
                                    </div>
                                    <h5>Quản lí nails salon dù ở bất kỳ đâu</h5>
                                    <div className="content">
                                          <p>
                                                Giúp cho salon nails hoạt động hiệu quả với nhiều tính năng tích hợp như quản lý nhân viên,  quản lý thông tin salon, thu chi, khách hàng
                                          </p>
                                    </div>
                              </li>
                              <li className="item">
                                    <div className="thumb">
                                          <img src={tabImageTwo} alt=""/>
                                    </div>
                                    <h5>Giao diện hiện đại dễ sử dụng</h5>
                                    <div className="content">
                                          <p>
                                                Chỉ với thao tác cơ bản, không quá 15 phút là bạn có thể thuần thục sử dụng ngay phần mềm quản lý salon nails. Giao diện hiện đại trực quan, phù hợp với các quản lý. Đội ngũ tư vấn chuyên nghiệp, tận tuỵ sẽ hỗ trợ tuyệt đối khách hàng nếu có nhũng trục trặ
                                          </p>
                                    </div>
                              </li>
                              <li className="item">
                                    <div className="thumb">
                                          <img src={tabImageThree} alt=""/>
                                    </div>
                                    <h5>Chăm sóc khách hàng trước và sau dùng dịch vụ nails</h5>
                                    <div className="content">
                                          <p>
                                                Hỗ trợ chức năng đặt lịch hẹn online cho khách hàng, tích hợp trên hệ thống website của công ty.. Đồng bộ dữ liệu với hệ thống giúp quản lý lịch hẹn, nhắc hẹn và điều phối nhân viên nails dễ dàng, chính xác, không bị lỡ lịch hẹn khách hàng
                                          </p>
                                    </div>
                              </li>
                        </ul>
                  </div>
            </section>
      );
}

export default CustomerSection;