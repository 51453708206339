import React from 'react';
import {Link} from "react-router-dom";
import logo from '../../assets/images/logo.png'

function Header(props) {
      return (
            <header className='header-main' id='header-main'>
                  <div className="wp-inner">
                        <Link to='/' className='thumb'>
                              <img src={logo} alt=""/>
                        </Link>
                        {!props.title ?
                              <p className="greeting">
                                    Giải pháp số hóa <span>cho ngành nail</span> chuyên nghiệp số 1
                              </p>
                        :
                              <p className="greeting">
                                    Trung tâm hỗ trợ Vietnails
                              </p>
                        }

                  </div>
            </header>
      );
}

export default Header;