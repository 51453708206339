import React from 'react';
import Master from "../../components/layouts/master";
import BannerTopSection from "./components/banner";
import ServiceSection from "./components/service";
import UtilitySection from "./components/utility";
import CustomerSection from "./components/customer";
import QuestionSection from "./components/question";
import FeatureSectionTop from "./components/features/top";
import FeatureSectionBottom from "./components/features/bottom";
import GallerySection from "./components/gallery";
import ReviewSection from "./components/review";
import StepSection from "./components/step";

function HomePage(props) {
      return (
            <Master>
                  <BannerTopSection/>
                  <ServiceSection/>
                  <UtilitySection/>
                  <FeatureSectionTop/>
                  <CustomerSection/>
                  <QuestionSection/>
                  <FeatureSectionBottom/>
                  <StepSection/>
                  <GallerySection/>
                  <ReviewSection/>
            </Master>
      );
}

export default HomePage;