import React from 'react';
import {Button} from "antd";
import image from '../../../../assets/images/feature/image.png'
import {Link} from "react-router-dom";

function FeatureSectionBottom(props) {

      // const downloadApp = (type) => {
      //       let url = type === 'ios' ? 'http://bit.ly/vietnails-customer-booking' : 'https://bit.ly/vietnail-customer-android'
      //       window.location.replace(url);
      // }

      return (
            <section className='feature-bottom-section'>
                  <div className="app">
                        <div className="left">
                              <div className="head">
                                    <p>Tải App</p>
                                    <p>Việt Nails - Owner</p>
                                    <p>Ngay hôm nay</p>
                              </div>
                              <div className="mid">
                                    <p>Quản lí thuận tiện, cập nhật kịp thời với ứng dụng dành cho mobile và tablet</p>
                              </div>
                              <div className="footer">
                                    <a href="http://bit.ly/vietnails-customer-booking" target='_blank'>
                                          <Button><i className="fa-brands fa-apple"></i> App store</Button>
                                    </a>
                                    <a  href="https://bit.ly/vietnail-customer-android" target='_blank'>
                                          <Button><i className="fa-brands fa-google-play"></i> Google play</Button>
                                    </a>
                              </div>
                        </div>
                        <div className="right">
                              <img src={image} alt=""/>
                              <div className="circle main"></div>
                        </div>
                  </div>
            </section>
      );
}

export default FeatureSectionBottom;