import React from 'react';
import {Avatar} from "antd";
import avatar1 from '../../../../assets/images/review/1.png'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

function ReviewSection(props) {
      const reviewData = [
            {
                  id : 1,
                  avatar: avatar1,
                  name: 'Helen',
                  salon: 'Nails Beauty and Spa',
                  content: <div>
                        <p>Trước đây tôi đã sử dụng rất nhiều phần mềm với mỗi tính năng riêng để quản lý chuỗi nail của tôi. Tuy nhiên nó thực sự không hiệu quả, xuất báo cáo khó khăn vào mỗi cuối tháng. Sau đó tôi được bạn giới thiệu về phần mềm chăm sóc khách hàng nail của Việt Nails, tích hợp nhiều tính năng cùng lúc. Điều tôi băn khoăn nhất trước khi sử dụng phần mềm quản lý salon online là liệu khi tích hợp nhiều tính năng thì phần mềm có bị rối và khó sử dụng không? Nhưng Việt Nails đã làm tôi thực sự yên tâm khi hệ thống được phân bố rất hợp lý và dễ dàng sử dụng. Hệ thống phần mềm spa chạy rất mượt và giúp tôi khá nhiều trong công tác quản lý nhân viên, hàng hóa cũng như dịch vụ chăm sóc khách hàng. Chi phí hàng tháng trước đây đều khiến tôi lo lắng nhưng giờ đã được phần mềm hỗ trợ toàn bộ. Tôi rất yên tâm.
                        </p>
                  </div>
            },
            {
                  id : 2,
                  avatar: avatar1,
                  name: 'Anton',
                  salon: 'True Beauty',
                  content: <div>
                        <p>Tôi đã phải quản lý khá cực, vì hệ thống spa của tôi có nhiều chi nhánh. Tuy nhiên, từ khi biết đến phần mềm spa, tôi đã điều hành từ xa hiệu quả toàn bộ chi nhánh của mình từ quản lý thu chi đến điều phối nhân viên.
                              Thời gian chấm công nhân viên của tôi hàng tháng rất rõ ràng, chi tiết và chính xác, giúp công tác vận hành trở nên dễ dàng hơn. Tôi cảm thấy rất hài lòng với phần mềm này. Đáng lẽ tôi nên biết đến phần mềm của Việt Nails sớm hơn để tiết kiệm thời  gian và chi phí trong thời gian qua.
                        </p>
                  </div>
            },
            {
                  id : 3,
                  avatar: avatar1,
                  name: 'Aine',
                  salon: 'Sweet Dreams Salon',
                  content: <div>
                        <p>Việt Nails đã giúp chúng tôi nhận và quản lý đơn đặt chỗ hiệu quả hơn và giúp chúng tôi rất nhiều trong việc quản lý và quảng bá hình ảnh. Được giới thiệu trên Việt Nails, salon chúng tôi đã thu hút được nhiều khách hàng hơn vào cả những lúc không cao điểm, đó là một điều tuyệt vời đối với bất kỳ salon nào. Chúng tôi cũng rất vui khi được hợp tác với những bạn trẻ nhiệt huyết và đam mê từ Việt Nails.</p>
                  </div>
            },
            {
                  id : 4,
                  avatar: avatar1,
                  name: 'Daniels',
                  salon: 'Good Lookin’ Salon',
                  content: <div>
                        <p>Với quy mô nhiều chi nhánh, mục tiêu mở rộng thêm nhiều chi nhánh spa ở các thành phố lớn khác. Nên công việc của tôi khá bận, sổ sách ngày càng nhiều và nhập liệu thông tin nhiều nơi  lại không hiệu quả. Tôi đã sử dụng giải pháp của Việt Nails để quản lý các chi nhánh của  mình. Hệ thống giúp tôi tối ưu lượng khách hàng hợp lý, đặt lịch hẹn cũng như theo dõi dịch vụ cho khách hàng rất chỉnh chu, đặc biệt là những dịp đông khách như lễ tết hay cuối tuần, khách không bị trùng lịch. Ngoài ra, phần mềm này cũng giúp tôi quản lý nhân viên, thu chi rất tiện lợi. Tôi đã bớt đi khá nhiều đầu việc vì đã có giải pháp quản lý spa thống kê và báo cáo chi tiết cho spa của mình.
                        </p>
                  </div>
            }
      ]

      const responsive = {
            desktop: {
                  breakpoint: { max: 3000, min: 1024 },
                  items: 1,
                  slidesToSlide: 1 // optional, default to 1.
            },
            tablet: {
                  breakpoint: { max: 1024, min: 464 },
                  items: 1,
                  slidesToSlide: 1 // optional, default to 1.
            },
            mobile: {
                  breakpoint: { max: 600, min: 0 },
                  items: 1,
                  slidesToSlide: 1 // optional, default to 1.
            }
      };

      const renderReviews = () => {
            return reviewData.map(item =>
                  <div key={item.id} className='review-item'>
                        <div className="inner">
                              <div className="head">
                                    <div className="left">
                                          <Avatar size={64} src={item.avatar}/>
                                    </div>
                                    <div className="right">
                                          <p>{item.name}</p>
                                          <p>Chủ tiệm {item.salon}</p>
                                    </div>
                              </div>
                              <div className="content">
                                    {item.content}
                              </div>
                        </div>
                  </div>
            )
      }

      return (
            <section className='review-section'>
                  <div className="wp-inner">
                        <div className='circle main'></div>
                        <h4 className="title-section">
                              Khách hàng nói gì về phần mềm quản lí Việt Nails?
                        </h4>

                        <Carousel
                              swipeable={false}
                              draggable={false}
                              responsive={responsive}
                              containerClass="carousel-container"
                              removeArrowOnDeviceType={["tablet", "mobile"]}
                        >
                              {renderReviews()}
                        </Carousel>
                  </div>
            </section>
      );
}

export default ReviewSection;