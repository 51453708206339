import React from 'react';
import {Route, Routes} from "react-router-dom";
import HomePage from "../features/Home";
import HelpPage from "../features/Help";
import ScrollToTop from "../scroll-top";

function ListRouters(props) {
      return (
            <>
                  <ScrollToTop  pathname={window.location.pathname}/>
                  <Routes>
                        <Route exact path='/' element={<HomePage/>}/>
                        <Route exact path='/instruction' element={<HelpPage/>}/>
                  </Routes>
            </>
      );
}

export default ListRouters;