import React, {useEffect} from 'react';


function ScrollToTop(props) {
      useEffect(() => {
            scrollToTop()
      }, [props.pathname])

      const scrollToTop = () => {
            window.scrollTo({
                  top: 10,
                  left: 0,
                  behavior: "smooth"
            });
      }
      return (
            <div>

            </div>
      );
}

export default ScrollToTop;