import React from 'react';
import Circle from "./circle";

function ServiceSection(props) {
      return (
            <section className='service-section'>
                  <svg id="wave" style={{transform: 'rotate(0deg)', transition: '0.3s'}} viewBox="0 0 1440 150" version="1.1" xmlns="http://www.w3.org/2000/svg"><defs><linearGradient id="sw-gradient-0" x1={0} x2={0} y1={1} y2={0}><stop stopColor="rgba(190, 67, 195, 1)" offset="0%" /><stop stopColor="rgba(190, 67, 195, 1)" offset="100%" /></linearGradient></defs><path style={{transform: 'translate(0, 0px)', opacity: 1}} fill="url(#sw-gradient-0)" d="M0,135L60,115C120,95,240,55,360,42.5C480,30,600,45,720,57.5C840,70,960,80,1080,77.5C1200,75,1320,60,1440,47.5C1560,35,1680,25,1800,25C1920,25,2040,35,2160,35C2280,35,2400,25,2520,20C2640,15,2760,15,2880,22.5C3000,30,3120,45,3240,52.5C3360,60,3480,60,3600,60C3720,60,3840,60,3960,65C4080,70,4200,80,4320,70C4440,60,4560,30,4680,37.5C4800,45,4920,90,5040,100C5160,110,5280,85,5400,65C5520,45,5640,30,5760,27.5C5880,25,6000,35,6120,37.5C6240,40,6360,35,6480,30C6600,25,6720,20,6840,20C6960,20,7080,25,7200,27.5C7320,30,7440,30,7560,35C7680,40,7800,50,7920,62.5C8040,75,8160,90,8280,85C8400,80,8520,55,8580,42.5L8640,30L8640,150L8580,150C8520,150,8400,150,8280,150C8160,150,8040,150,7920,150C7800,150,7680,150,7560,150C7440,150,7320,150,7200,150C7080,150,6960,150,6840,150C6720,150,6600,150,6480,150C6360,150,6240,150,6120,150C6000,150,5880,150,5760,150C5640,150,5520,150,5400,150C5280,150,5160,150,5040,150C4920,150,4800,150,4680,150C4560,150,4440,150,4320,150C4200,150,4080,150,3960,150C3840,150,3720,150,3600,150C3480,150,3360,150,3240,150C3120,150,3000,150,2880,150C2760,150,2640,150,2520,150C2400,150,2280,150,2160,150C2040,150,1920,150,1800,150C1680,150,1560,150,1440,150C1320,150,1200,150,1080,150C960,150,840,150,720,150C600,150,480,150,360,150C240,150,120,150,60,150L0,150Z" /></svg>
                  <div className="wp-inner">
                        <h4 className='title-section white'>Tại sao bạn nên chọn giải pháp số hóa Việt Nails?</h4>
                        <ul>
                              <li className='item'>
                                    <h5>Cập nhật thường xuyên giải pháp số hóa</h5>
                                    <p>
                                          Các tính năng của các giải pháp số hóa cho ngành sắc đẹp được Việt Nails cập nhật liên tục đáp ứng đầy đủ nhu cầu của hầu hết các chủ kinh doanh
                                    </p>
                              </li>
                              <li className="item">
                                    <h5>Giá rẻ so hơn với thị trường</h5>
                                    <p>
                                          Chi phí các giải pháp số hóa rẻ hơn so với mặt bằng chung của thị trường tuy nhiên các tính năng của giải pháp số hóa đều vượt trội và phù hợp với cơ sở làm đẹp
                                    </p>
                              </li>
                              <li className="item">
                                    <h5>Bắt kịp xu hướng chuyển đổi số</h5>
                                    <p>
                                          Việt Nails là một trong những công ty luôn đổi mới và bắt kịp với xu hướng chuyển đổi số, đẩy doanh nghiệp làm đẹp lên kinh doanh online
                                    </p>
                              </li>
                              <li className="item">
                                    <h5>Đội ngũ chuyên nghiệp</h5>
                                    <p>
                                          Đội ngũ hỗ trợ lâu năm kinh nghiệm, có chuyên môn cao. Luôn theo dõi và cập nhật hàng ngày để đảm bảo quá trình sử dụng không bị ngắt quãng và tạo thuận lợi trong các bước thao tác. Đảm bảo update liên tục toàn bộ tính năng giúp khách hàng hài lòng. Doanh nghiệp có thể hoàn toàn yên tâm với đội ngũ kỹ thuật chuyên nghiệp, đặt lợi ích của khách hàng lên hàng đầu, giúp phần mềm tương thích với đa số các dòng máy
                                    </p>
                              </li>
                              <li className="item">
                                    <h5>Tăng doanh thu cho salon của bạn</h5>
                                    <p>
                                          Thống kê của chúng tôi cho thấy, có tới 40% đơn đặt được thực hiện ngoài giờ làm việc của salon.
                                          Việt Nails có lực lượng  làm marketing hùng hậu đem lại nguồn khách hàng dồi dào cho salon của quý khách, kể cả trong thời gian thấp điểm, đó là một đi
                                    </p>
                              </li>

                              <li className="item">
                                    <h5>Hỗ trợ trước, trong và sau bán</h5>
                                    <p>
                                          Trước, trong và sau khi mua phần mềm, khách hàng sẽ được nhân viên hỗ trợ hướng dẫn sử dụng và khắc phục mọi khó khăn khi sử dụng phần mềm 24/7. Đội ngũ tư vấn tận tình và nhiều kinh nghiệm sẽ giúp doanh nghiệp khắc phục được những vấn đề thường gặp trong quản lý spa, tiết kiệm thời gian cũng như tiền bạc
                                    </p>
                              </li>
                        </ul>

                        <Circle/>
                  </div>
            </section>
      );
}

export default ServiceSection;