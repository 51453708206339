import React, {useEffect, useRef, useState} from 'react';
import Header from "../../components/layouts/header";
import {Collapse, Input, Select} from "antd";
import '../../stylesheets/help/index.scss'

function HelpPage(props) {

      const [questions, setQuestions ] = useState([
            {
                  _id : 1,
                  question : 'Tôi phải tải ứng dụng tại đâu ?',
                  answer : <div className='answer-item'>
                        <p className="title">Tải và cài đặt ứng dụng Việt Nails dành cho chủ tiệm:</p>
                        <p className="desc">
                              - Dành cho điện thoại iPhone: Vào App Store > Nhập vào ô tìm kiếm từ khoá : <span>Viet Nails - Chủ Tiệm / Thợ</span> > nhấn <span>"Nhận"</span> > Đợi ứng dụng được tải trong giây lát > Nhấn <span>"Mở"</span> để bắt đầu sử dụng ứng dụng
                        </p>
                        <div className="image two">
                              <div>
                                    <img src="assets/images/q-1/1.png" alt=""/>
                              </div>

                              <div>
                                    <img src="assets/images/q-1/2.png" alt=""/>
                              </div>
                        </div>
                        <p className="desc">- Dành cho điện thoại Samsung, HTC, ..( Android) > Vào CH Play > Nhập vào ô tìm kiếm từ khoá : Việt Nails - Chủ Tiệm / Thợ > nhấn "Cài đặt" > Đợi ứng dụng được tải trong giây lát > Nhấn "Mở" để bắt đầu sử dụng ứng dụng.</p>
                        <div className="image two">
                              <div>
                                    <img src="assets/images/q-1/3.png" alt=""/>
                              </div>

                              <div>
                                    <img src="assets/images/q-1/4.png" alt=""/>
                              </div>
                        </div>
                  </div>
            },
            {
                  _id : 2,
                  question : 'Làm cách nào để đăng kí / tạo salon trên ứng dụng ?',
                  answer : <div className='answer-item'>
                        <p className="title">Cách đăng kí để mở salon trên ứng dụng Việt Nails:</p>

                        <div className="step">
                              <p className="title-step"><span className="step">Bước 1:</span>
                                    Mở ứng dụng (tại màn hình đăng nhập) > nhấn vào nút " <span>Nhấn vào đây để đăng ký</span>" ở mục "Bạn là chủ tiệm muốn đăng ký cho Salon?"
                              </p>
                        </div>
                        <div className="image two">
                              <div>
                                    <img src="assets/images/q-2/1.png" alt=""/>
                              </div>

                              <div>
                                    <img src="assets/images/q-2/2.png" alt=""/>
                              </div>
                        </div>
                        <div className="step">
                              <p className="title-step"><span className="step">Bước 2:</span>
                                    Tiến hành đăng ký: Có 2 cách để chủ tiệm có thể đăng kí
                              </p>
                              <p className="desc">
                                    <span>+ Cách 1:</span> Dành cho chủ tiệm không có thời gian điền vào biểu mẫu đăng kí hoặc không biết đăng kí > chủ tiệm hãy nhấn vào nút "<span> Nhấn vào đây để chúng tôi hỗ trợ</span>" > Sau đó hãy gửi hình ảnh bussiness card hoặc điền vào thông tin liên hệ của bạn (Phone hoặc email) > nhấn nút <span>" Gửi yêu cầu hỗ trợ đăng ký"</span> <span> ==> Đội ngũ chăm sóc khách hàng của Việt Nails sẽ chủ động liên hệ đến chủ tiệm hỗ trợ chủ tiệm đăng kí.</span>
                              </p>
                              <p className="desc"><span>+ Cách 2:</span> Dành cho chủ tiệm muốn tự đăng kí > nhấn vào nút <span>"Nhấn vào đây để đăng kí"</span> > Chủ tiệm cung cấp thông tin salon theo hướng dẫn trên ứng dụng theo từng bước và nhấn tiếp tục > Sau khi điền toàn bộ thông tin , ở bước cuối cùng nhấn "Đăng ký" để hoàn tất.</p>
                        </div>

                        <div className="image one">
                              <div>
                                    <img src="assets/images/q-2/3.png" alt=""/>
                              </div>
                        </div>
                  </div>
            },
            {
                  _id : 3,
                  question : 'Trong quá trình đăng kí tài khoản, tôi được thông báo là số điện thoại đăng kí đã tồn tại và không thể tiếp tục đăng ký, tôi phải làm sao ?',
                  answer : <div className='answer-item'>
                        <p className="desc">
                              - Trường hợp này có thể đã có một người khác đăng kí salon dưới số điện thoại của quý vị hoặc cũng có thể là đội ngũ Việt Nails đã tạo sẵn thông tin cho salon của quý vị trước đó
                        </p>
                        <p className="desc">
                              - Nếu chắc chắn rằng đó là số điện thoại của tiệm, quý vị hãy liên hệ bộ phận Chăm sóc khách hàng của Việt Nails để được kiểm tra và hỗ trợ.
                        </p>
                        <div className="image one">
                              <div>
                                    <img src="assets/images/q-3/1.png" alt=""/>
                              </div>
                        </div>
                        <div className="image one">
                              <div>
                                    <img src="assets/images/q-3/2.png" alt=""/>
                              </div>
                        </div>
                        <div className="contact-block">
                              <div className="title-contact">Quý vị có thể liên hệ với Việt Nails qua: </div>
                              <p className='desc'>- Phone: (518) 805-184</p>
                              <p className='desc'>- Email: vietnails.us@gmail.com</p>
                              <p className='desc'> - Nhắn tin trực tiếp trên Fanpage: https://www.facebook.com/123vietnails</p>
                        </div>
                  </div>
            },
            {
                  _id : 4,
                  question : '"Quản lý thông tin salon" là gì? Cách để chủ tiệm có thể chỉnh sửa thông tin tiệm, thời gian hoạt động (giở mở cửa) của tiệm.',
                  answer : <div className='answer-item'>

                        <p className="desc">Để chỉnh sửa thông tin tiệm, chủ tiệm vào mục <span>"Quản lý thông tin salon"</span> > nhấn vào nút <span>"Sửa"</span> ở góc phải màn hình > tiếp theo nhập thông tin cần chỉnh sửa > sau khi đã thay đổi thông tin hãy nhấn nút <span>"Lưu"</span> để hoàn tất chỉnh sửa.</p>
                        <p className="desc">Ngoài ra, nếu chủ tiệm muốn tạo chương trình khuyến mãi (Voucher) cho khách hàng > chủ tiệm vào mục <span>"Quản lý thông tin salon"</span> nhấn <span>"Thêm Ưu Đãi"</span> > sau đó nhập thông tin ưu đãi như mã ưu đãi, giá trị giảm (theo phần trăm hoặc theo số tiền cố định), ngày bắt đầu, ngày hết hạn, chọn kiểu khách hàng (Tất cả / Vip / Thường),  nhập thêm mô tả về chương trình khuyến mãi > tiếp đó nhấn nút <span>"Áp dụng"</span> là đã tạo voucher thành công</p>
                        <div className="image one">
                              <div>
                                    <img src="assets/images/q-4/1.png" alt=""/>
                              </div>
                        </div>
                        <div className="note">
                              <p>
                                    (Lưu ý: phần mô tả về thông tin salon, chủ tiệm nên viết bằng tiếng Anh để khách hàng người nước ngoài khi xem thông tin salon có thể đọc.  Và thông tin múi giờ của cửa tiệm sẽ được hệ thống tự động thiết lập theo địa chỉ của salon)
                              </p>
                        </div>
                  </div>
            },
            {
                  _id : 5,
                  question : 'Tôi có thể giới thiệu salon của tôi đến bạn bè hay khách hàng được không. Làm sao để chia sẻ thông tin salon của tôi?',
                  answer : <div className='answer-item'>
                        <p className="desc">
                              Để chia sẻ thông tin salon, chủ tiệm vào mục <span>"Quản lý thông tin salon"</span> > tiếp đó nhấn nút <span>"Chia sẻ"</span>  và chọn nơi muốn chia sẻ.
                        </p>
                        <div className="image two">
                              <div>
                                    <img src="assets/images/q-5/1.png" alt=""/>
                              </div>
                              <div>
                                    <img src="assets/images/q-5/2.png" alt=""/>
                              </div>
                        </div>
                        <div className="image one">
                              <div>
                                    <img src="assets/images/q-5/3.png" alt=""/>
                              </div>
                        </div>
                        <div className="note">
                              <p>(Lưu ý: Thông tin được chia sẻ chỉ hiển thị một số nội dung cơ bản về salon, để xem đầy đủ hơn về thông tin salon, người xem cần phải tải ứng dụng để xem chi tiết )</p>
                        </div>
                  </div>
            },
            {
                  _id : 6,
                  question : 'Tôi muốn tạo mã giảm giá / voucher khuyến mãi cho khách hàng thì phải làm sao?',
                  answer : <div className='answer-item'>
                        <p className="desc">
                              Chủ tiệm muốn tạo <span>chương trình khuyến mãi (Voucher)</span> cho khách hàng > chủ tiệm vào mục "Quản lý thông tin salon" nhấn "Thêm Ưu Đãi" > sau đó nhập thông tin ưu đãi như mã ưu đãi, giá trị giảm (theo phần trăm hoặc theo số tiền cố định), ngày bắt đầu, ngày hết hạn, chọn kiểu khách hàng (Tất cả / Vip / Thường), nhập thêm mô tả về chương trình khuyến mãi > tiếp đó nhấn nút "Áp dụng" là đã tạo voucher thành công.
                        </p>
                        <div className="image one">
                              <div>
                                    <img src="assets/images/q-6/1.png" alt=""/>
                              </div>
                        </div>
                        <div className="image one">
                              <div>
                                    <img src="assets/images/q-6/2.png" alt=""/>
                              </div>
                        </div>
                        <div className="note">
                              <p>(Lưu ý: để khách hàng có thể thấy và sử dụng mã khuyến mã. Chủ tiệm cần chia sẻ mã cho khách hàng)</p>
                        </div>
                  </div>
            },
            {
                  _id : 7,
                  question : 'Mục "Quản lý dịch vụ" là gì ? Cách tạo dịch vụ như thế nào?',
                  answer : <div className='answer-item'>
                        <p className='desc'>
                              Quản lý dịch vụ giúp chủ tiệm có thể Tạo / Xem / Sửa thông tin dịch vụ của salon. Ngoài ra chủ tiệm có thể huỷ kích hoạt hoặc xoá dịch vụ khi không còn phục vụ dịch vụ đó tại cửa tiệm.
                        </p>
                        <p className="desc">
                              - Mỗi dịch vụ sẽ bao gồm : Ảnh dịch vụ, tên dịch vụ, giá, ảnh chi tiết
                        </p>
                        <p className="desc">
                              - Để tạo dịch vụ, chủ tiệm nhấn vào danh mục <span>"Quản lý các dịch vụ"</span> > sau đó nhấn chọn <span>"Thêm dịch vụ"</span> > tiếp theo nhập thông tin như ảnh bìa, tên dịch vụ, giá, ảnh chi tiết > cuối cùng nhấn nút <span>"Cập nhật"</span> để tạo dịch vụ.
                        </p>
                        <div className="image two">
                              <div>
                                    <img src="assets/images/q-7/1.png" alt=""/>
                              </div>
                              <div>
                                    <img src="assets/images/q-7/2.png" alt=""/>
                              </div>
                        </div>
                        <div className="image two">
                              <div>
                                    <img src="assets/images/q-7/3.png" alt=""/>
                              </div>
                              <div>
                                    <img src="assets/images/q-7/4.png" alt=""/>
                              </div>
                        </div>

                        <div className="note">
                              <p>(Lưu ý: hình ảnh sản phẩm là yếu tố ảnh hưởng đến quyết định mua hàng của người dùng, khi tạo dịch vụ có mục ảnh chi tiết, tại đây chủ tiệm nên thêm nhiều hình ảnh đẹp về dịch vụ của cửa tiệm giúp thu hút khách hàng đặt dịch vụ của salon bạn hơn)</p>
                        </div>
                  </div>
            },
            {
                  _id : 8,
                  question : 'Mục "Quản lý khách hàng" có chức năng ra sao ? Chủ tiệm có thể thao tác gì ở đó?',
                  answer : <div className='answer-item'>
                        <p className="desc">
                              Mục <span>"Quản lý khách hàng"</span> sẽ hiển thị thông tin về khách hàng như thông tin các nhân (số điện thoại, ngày tháng năm sinh, mail, địa chỉ, ghi chú), thông tin hành vi (số lượng booking, thời gian thường booking, dịch vụ thường booking), thông tin tương tác (mức độ hài lòng, rating, đánh giá dịch vụ)  của khách hàng đã từng đặt dịch vụ của cửa tiệm trên ứng dụng Việt Nails.
                        </p>
                        <p className="desc">
                              - Tạo lịch đặt hẹn cho khách hàng.
                        </p>
                        <p className="desc">
                              - Phân nhóm khách hàng để thuận tiện cho việc áp dụng các mã voucher cho đối tượng khách hàng khác nhau.
                        </p>

                        <div className="image one">
                              <div>
                                    <img src="assets/images/q-8/1.png" alt=""/>
                              </div>
                        </div>
                        <div className="note">
                              <p>(Lưu ý: Những thông tin này giúp chủ tiệm dễ dàng quản lý và phân nhóm khách hàng theo cùng tiêu chí để phục vụ việc chăm sóc khách hàng và thực hiện các chiến dịch marketing và remarketing ...)</p>
                        </div>
                  </div>
            },
            {
                  _id : 9,
                  question : 'Mục "Quản lý nhân viên" dùng để làm gì? Sử dụng ra sao?',
                  answer : <div className='answer-item'>
                        <p className="desc">Quản lý nhân viên giúp chủ tiệm có thể</p>
                        <p className="desc">- Tạo / Xem / Sửa thông tin thợ của tiệm. Ngoài ra, chủ tiệm có thể huỷ kích hoạt hoặc xoá thợ khi thợ không còn làm việc tại tiệm.</p>
                        <p className="desc">- Xem thông tin mỗi thợ sẽ bao gồm: Ảnh đại diện, tên thợ, số điện thoại liên lạc, thông tin ghi chú về thợ</p>
                        <p className="desc">- Xem lịch sử và quản lý thời gian làm việc của thợ: Chủ tiệm (hoặc thợ) sẽ vào ứng dụng nhấn <span>check-in/ check-out</span>, hệ thống sẽ thống kê và lưu lại giờ làm việc mỗi ngày của từng thợ (Chủ tiệm nhấn vào biểu tượng "⋮" ở mỗi thợ > sau đó nhấn <span>"Lịch sử đi làm"</span> để xem)</p>
                        <p className="desc">- Xem lịch sử tất cả các booking mà thợ đã làm cho khách hàng(Chủ tiệm nhấn vào biểu tượng "⋮" ở mỗi thợ > sau đó nhấn <span>"Lịch sử làm việc"</span> để xem)</p>

                        <div className="image two">
                              <div>
                                    <img src="assets/images/q-9/1.png" alt=""/>
                              </div>
                              <div>
                                    <img src="assets/images/q-9/2.png" alt=""/>
                              </div>
                        </div>
                        <div className="image one">
                              <div>
                                    <img src="assets/images/q-9/3.png" alt=""/>
                              </div>
                        </div>
                        <div className="note">
                              <p>
                                    (Lưu ý: Thợ cần check-in thì salon mới có thể nhận đơn đặt dịch vụ và phục vụ khách hàng. Thợ chỉ có thể check-in được trong thời gian hoạt động của salon. Mỗi ngày thợ sẽ tự động check-out khi hết giờ hoạt động của salon.)
                              </p>
                        </div>
                  </div>
            },
            {
                  _id : 10,
                  question : 'Mục "Giám sát nhân viên" trong ứng dụng có giúp ích được gì cho chủ tiệm không?',
                  answer : <div className='answer-item'>
                        <p className="desc">
                              Giám sát nhân viên là chức năng giúp chủ tiệm có thể dễ dàng nắm rõ tình hình công việc cụ thể của từng nhân viên trong ngày, chủ tiệm sẽ dựa vào trạng thái công việc hiện tại của nhân viên tại tiệm để giao việc.
                        </p>
                        <p className="desc">
                              Bảng giám sát sẽ hiển thị các thông tin sau:
                        </p>
                        <p className="desc">- Tên nhân viên</p>
                        <p className="desc">- Trạng thái hiện tại: Đang bận / đang rảnh / vắng mặt </p>
                        <p className="desc">- Thông tin booking nhân viên đang phục vụ: Mã booking, tên khách hàng, thời gian thợ bắt đầu phục vụ.</p>
                        <div className="image one">
                              <div>
                                    <img src="assets/images/q-10/1.png" alt=""/>
                              </div>
                        </div>
                        <div className="image one">
                              <div>
                                    <img src="assets/images/q-10/2.png" alt=""/>
                              </div>
                        </div>
                        <div className="note">
                              <p>(Lưu ý: Thợ cần check-in thì salon mới có thể nhận đơn đặt dịch vụ và phục vụ khách hàng. Thợ chỉ có thể check-in được trong thời gian hoạt động của salon. Mỗi ngày thợ sẽ tự động check-out khi hết giờ hoạt động của salon.)</p>
                        </div>
                  </div>
            },
            {
                  _id : 11,
                  question : 'Làm sao để chủ tiệm tạo lịch hẹn mới cho khách hàng?',
                  answer : <div className='answer-item'>
                        <p className="desc">
                              Để tạo lịch đặt hẹn cho khách hàng, chủ tiệm vào mục <span>"Quản lý lịch đặt hẹn"</span> > nhấn nút <span>"Tạo mới cuộc hẹn"</span> > tiếp theo nhập vào thông tin khách hàng (có thể chọn từ danh sách khách hàng cũ hoặc nhấn thêm khách hàng để tạo khách hàng mới. ) > sau đó nhập thông tin yêu cầu từ khách hàng như nhân viên sẽ phục vụ, chọn thời gian khách tới làm, dịch vụ khách muốn đặt, thời gian ước tính thực hiện các dịch vụ được yêu cầu, thêm ghi chú (nếu có) > sau đó nhấn "Gửi" là tạo lịch hẹn thành công.
                        </p>
                        <p className="desc">
                              Khi chủ tiệm tạo lịch hẹn mới, Việt Nails sẽ gửi thông báo trong ứng dụng đến cho khách hàng, trường hợp nếu chủ tiệm tạo lịch hẹn cho khách hàng mới (chưa từng đặt lịch trên ứng dụng Việt Nails) thì hệ thống sẽ tự động tạo một tài khoản cho khách hàng để khách hàng có thể vào xem thông tin lịch hẹn.
                        </p>
                        <div className="image one">
                              <div>
                                    <img src="assets/images/q-11/1.png" alt=""/>
                              </div>
                        </div>
                        <div className="note">
                              <p>(Lưu ý: chủ tiệm hướng dẫn cho khách hàng tải ứng dụng Việt Nails - Customer và đăng nhập vào tài khoản để xem thông tin lịch hẹn, Tài khoản được tạo tự động với thông tin đăng nhập là số điện thoại của khách hàng, mật khẩu cũng sẽ là số điện thoại của khách hàng. Ví dụ:Khách hàng có số điện thoại là 1234567890 , thì khi đăng nhập sẽ nhập vào </p>
                              <p>- Số điện thoại đăng nhập: 1234567890 </p>
                              <p>- Mật khẩu: 1234567890 </p>
                        </div>
                  </div>
            },
            {
                  _id : 12,
                  question : 'Khi có khách hàng đặt lịch thì tôi vào đâu để xem thông tin?',
                  answer : <div className='answer-item'>
                        <p className="desc">
                              Khi có khách hàng đặt hẹn, Việt Nails sẽ gửi thông báo đến cho chủ tiệm trên ứng dụng. Chủ tiệm chỉ cần nhấn vào <span>thông báo</span> là có thể xem thông tin chi tiết hoặc vào mục <span>"Quản lý lịch đặt hẹn"</span> để xem tất cả lịch hẹn tại cửa tiệm.
                        </p>
                        <div className="image one">
                              <div>
                                    <img src="assets/images/q-12/1.png" alt=""/>
                              </div>
                        </div>

                        <div className="image one">
                              <div>
                                    <img src="assets/images/q-12/2.png" alt=""/>
                              </div>
                        </div>
                        <div className="note">
                              <p>(Lưu ý: khi khách hàng đặt hệ thống sẽ tự xác nhận lịch hẹn và thông báo cho chủ tiệm, trường hợp cửa tiệm không thể phục vụ theo yêu cầu của khách, chủ tiệm có thể chủ động liên hệ với khách hàng để thương lượng lại, sau khi thương lượng có thể vào chi tiết cuộc hẹn để cập nhật lại thông tin mới. Trường hợp không thể thương lượng chủ tiệm có thể huỷ lịch đặt hẹn trong ứng dụng )</p>
                        </div>
                  </div>
            },
            {
                  _id : 13,
                  question : 'Cách nhận và hoàn thành 1 lịch đặt hẹn trên ứng dụng là như thế nào?',
                  answer : <div className='answer-item'>
                        <p className="desc">Quy trình để nhận và hoàn thành đặt hẹn gồm 3 bước:</p>
                        <p className="desc"></p>
                        <div className="step">
                              <p className="title-step">
                                    Bước 1: Đặt hẹn và xác nhận lịch đặt hẹn
                              </p>
                              <p className="desc">
                                    - Khi khách hàng đặt lịch hẹn trên ứng dụng Việt Nails - Customer, hệ thống sẽ tự động chấp nhận lịch hẹn và gửi thông báo đến cho chủ tiệm.
                              </p>
                        </div>

                        <div className="step">
                              <p className="title-step">
                                    Bước 2: Xem thông tin và tiến hành phục vụ
                              </p>
                              <p className="desc">
                                    - Khi nhận được thông báo, chủ tiệm vui lòng vào xem thông tin lịch hẹn. (Vào mục <span>"quản lý lịch đặt hẹn"</span> để xem.)
                              </p>
                              <p className="desc">
                                    - Gần đến thời gian hẹn, chủ tiệm vào "nhắc hẹn" khách. (Vào mục <span>"quản lý lịch đặt hẹn"</span> > nhấn <span>"nhắc nhở"</span>, hệ thống sẽ gửi thông báo nhắc hẹn đến khách hàng.)
                              </p>
                              <p className="desc">
                                    - Đến ngày hẹn, khách hàng đến cửa tiệm, chủ tiệm xác nhận khách hàng đến cửa tiệm (Vào mục <span>"quản lý lịch đặt hẹn"</span> > nhấn "Khách check-in", trường hợp khách không đến chủ tiệm nhấn "Huỷ" để huỷ bỏ lịch hẹn.)
                              </p>
                              <p className="desc">
                                    - Tiếp đó chủ tiệm chỉ định nhân viên và ước tính thời gian phục vụ của thợ (Vào mục <span>"quản lý danh sách booking"</span>, nhấn <span>"Bắt đầu phục vụ"</span> > chọn nhân viên phục vụ, nhập thời gian làm dịch vụ.)
                              </p>

                        </div>
                        <div className="step">
                              <p className="title-step">
                                    Bước 3: Hoàn tất lịch hẹn
                              </p>
                              <p className="desc">- Sau khi thợ hoàn thành công việc phục vụ khách hàng, chủ tiệm xác nhận và vào ứng dụng nhấn hoàn tất.</p>
                              <p className="desc"> - Chủ tiệm có thể cập nhật giá tiền cho booking khi có thay đổi phát sinh trong quá trình phục vụ.   Ví dụ: khách hàng đặt thêm dịch vụ, huỷ dịch vụ, ...</p>
                              <p className="desc">- Chủ tiệm có thể đăng tải ảnh so sánh kết quả trước và sau khi khách hàng sử dụng dịch vụ tại tiệm.  (Vào mục <span>"quản lý danh sách booking"</span>, nhấn <span>"Hoàn tất"</span>, nhập dịch vụ phát sinh (nếu có) và ghi chú (nếu có), đăng tải ảnh trước và sau khi phục vụ.)</p>
                        </div>

                        <div className="image one">
                              <div>
                                    <img src="assets/images/q-13/1.png" alt=""/>
                              </div>
                        </div>

                        <div className="note">
                              <p>  (Lưu ý:  - Sau khi hoàn tất dịch vụ, khách hàng sẽ có thể vào gửi đánh giá và gửi bình luận chất lượng dịch vụ của salon. Hãy phuc vụ khách hàng một cách tốt nhất để nhận được sự hài lòng, đánh giá tốt, từ đó thu hút được thêm nhiều khách hàng mới cho cửa tiệm. Tránh những phản hồi tiêu cực ảnh hưởng đến cửa tiệm</p>
                        </div>
                  </div>
            },
            {
                  _id : 14,
                  question : 'Làm sao để tôi thay đổi thông tin dịch vụ phát sinh mà khách hàng yêu cầu thêm hay thay đổi giá tiền cho booking ?',
                  answer : <div className='answer-item'>
                        <div className="note">
                              <p>Vào "Quản lý danh sách booking" > Chọn "Đơn hàng" > Hoàn Tất > Thêm dịch vụ phát sinh</p>
                              <p>> Chọn dịch vụ có sẵn </p>
                              <p>> Thêm mới: Các dịch vụ mới, mã giảm giá...</p>
                        </div>
                        <div className="step">
                              <p className="title-step">
                                    Có 2 trường hợp xảy ra khi khách hàng muốn thay đổi thông tin dịch vụ trên ứng dụng:
                              </p>
                        </div>
                        <div className="step">
                              <div className="title-step">
                                    <p><span>Trường hợp 1:</span> Chủ tiệm cần thay đổi thông tin booking trước khi nhấn <span>"Bắt đầu phục vụ"</span></p>
                              </div>
                              <p className="desc">- Chủ tiệm vào mục "Quản lý lịch đặt hẹn" > chọn và nhấn lịch hẹn cần thay đổi > sau đó nhấn "Sửa" và thay đổi thông tin theo ý muốn.</p>
                              <p className="desc">- Khi khách đã đến tiệm, chủ tiệm đã nhấn "Khách hàng check-in" > Chủ tiệm vào "Quản lý danh sách booking" > chọn và nhấn lịch hẹn cần thay đổi > sau đó nhấn "Sửa" và thay đổi thông tin theo ý muốn.</p>
                        </div>
                        <div className="step">
                              <div className="title-step">
                                    <p><span>Trường hợp 2:</span> Chủ tiệm muốn thay đổi sau khi đã nhấn <span>Trường hợp 2:</span></p>
                              </div>
                              <p className="desc">- Chủ tiệm vào "Quản lý danh sách booking" > chọn và nhấn lịch hẹn cần thay đổi > tiếp đó nhấn "Hoàn thành" , một cửa sổ xác nhận thông tin hiện lên > tại đây chủ tiệm có thể chỉnh sửa thông tin dịch vụ theo ý muốn như: thêm phí dịch vụ phát sinh (nếu khách hàng yêu cầu thêm), giảm giá bằng cách khi nhập số tiền khách hãy chọn giá âm (nếu lúc đặt hẹn khách hàng quên nhập hoặc tới tiệm vào lúc có chương trình khuyến mãi).</p>
                              <p className="desc">Ví dụ: Khách đến làm nail yêu cầu thêm 1 dịch vụ phát sinh là "Massage tay, giá 5$" và tiệm thấy khách dễ thương giảm giá cho khách $2. Thì trên ứng dụng chủ tiệm làm như sau:</p>
                              <p className="desc">- Nhấn vào booking đang phục vụ > nhấn "hoàn tất" > ứng dụng sẽ hiển thị một cửa sổ nhỏ, chủ tiệm nhấn "Thêm mới" ></p>
                              <p className="desc">- Tiếp đó nhập tên dịch vụ bằng " Massage tay" , nhập giá bằng: "5$", nhấn nút "thêm"</p>
                              <p className="desc"> - Sau đó lại tiếp tục nhập tên dịch vụ bằng "Giảm giá cho khách hàng dễ thương" , nhập giá bằng "2$" , nhấn nút " - " , nhấn nút "thêm" ;</p>
                              <p className="desc">- Cuối cùng nhấn nút "Gửi" để lưu thay đổi và hoàn tất booking</p>
                        </div>
                        <div className="image one">
                              <div>
                                    <img src="assets/images/q-14/1.png" alt=""/>
                              </div>
                        </div>
                  </div>
            },
            {
                  _id : 15,
                  question : 'Khi khách hàng tới check - in tại tiệm, tôi phải làm sao để cho khách sử dụng ứng dụng đặt dịch vụ?',
                  answer : <div className='answer-item'>
                        <div className="step">
                              <p className="title-step">
                                    - Để khách hàng đến tiệm và đặt dịch vụ trực tiếp tại tiệm chủ tiệm thực hiện như sau:
                              </p>
                        </div>
                        <div className="step">
                              <p className="desc">
                                    <span>Bước 1:</span>   Chủ tiệm cần sử dụng thêm ít nhất 1 thiết bị khác (điện thoại hoặc tablet/ipad), tốt nhất là nên dùng iPad/tablet để màn hình hiển thị to rõ, khách hàng dễ sử dụng hơn.
                              </p>
                        </div>
                        <div className="step">
                              <p className="desc"><span>Bước 2:</span> Tải và cài đặt ứng dụng "Việt Nails - Chủ Tiệm/ Thợ" </p>
                        </div>
                        <div className="step">
                              <p className="desc"><span>Bước 3:</span> Đăng nhập tài khoản chủ tiệm > sau đó nhấn nút "Khách walk -in" để chuyển qua chế độ dành cho Khách check-in</p>
                        </div>
                        <div className="step">
                              <p className="desc"><span>Bước 4:</span> Đưa thiết bị cho khách hàng nhập số điện thoại , chọn dịch vụ , chọn thợ</p>
                        </div>


                        <div className="image one">
                              <div>
                                    <img src="assets/images/q-15/1.png" alt=""/>
                              </div>
                        </div>
                        <div className="note">
                              <p>(Lưu ý: Nếu khách hàng của tiệm đông thì cần dùng nhiều thiết bị hơn, cho mỗi khách dùng 1 thiết bị hoặc nếu không có nhiều thiết bị, thì sau khi khách trước đặt dịch vụ xong thì chủ tiệm sẽ lấy lại thiết bị và đưa cho khách tiếp theo sử dụng )</p>
                        </div>
                  </div>
            },
            {
                  _id : 16,
                  question : 'Tôi muốn đăng tin tuyển dụng thợ làm nails cho tiệm của mình, tôi phải vào đâu ?',
                  answer : <div className='answer-item'>
                        <div className="step">
                              <p className="title-step">
                                    Để ẩn tin tuyển dụng bạn cần vào danh mục "Tin tuyển dụng thợ nail của tôi" > chọn tin bạn muốn ẩn > nhấn Ẩn tin.
                              </p>
                        </div>
                        <div className="note">
                              <p>(Lưu ý: Bạn có thể hiển thị lại tin sau khi ẩn bằng cách nhấn "Hiện tin" )  </p>
                        </div>
                        <div className="image one">
                              <div>
                                    <img src="assets/images/q-16/1.png" alt=""/>
                              </div>
                        </div>

                        <div className="step">
                              <p className="title-step">
                                    Để xoá tin tuyển dụng bạn vào danh mục "Tin tuyển dụng thợ nail của tôi" > tiếp đó chọn vào tin bạn muốn xoá > nhấn chọn "Xoá" > Xác nhận một lần nữa bạn có chắc chắn muốn xoá không.
                              </p>
                        </div>
                        <div className="note">
                              <p>(Lưu ý: Một khi xác nhận xoá vĩnh viễn thì bạn sẽ không thể khôi phục lại dữ liệu đã xoá )  </p>
                        </div>
                        <div className="image one">
                              <div>
                                    <img src="assets/images/q-16/2.png" alt=""/>
                              </div>
                        </div>
                  </div>
            },
            {
                  _id : 17,
                  question : 'Tôi vào đâu để xem lại bài đăng tuyển dụng của tôi ?',
                  answer : <div className='answer-item'>
                        <div className="step">
                              <p className="title-step">
                                    Để xem lại bài tin tuyển dụng bạn vào mục "Tin tuyển dụng thợ nail của tôi" sẽ thấy một danh sách tin tuyển dụng bạn đã đăng.
                              </p>
                        </div>
                        <div className="image one">
                              <div>
                                    <img src="assets/images/q-17/1.png" alt=""/>
                              </div>
                        </div>
                        <div className="image one">
                              <div>
                                    <img src="assets/images/q-17/2.png" alt=""/>
                              </div>
                        </div>
                  </div>
            },
            {
                  _id : 18,
                  question : 'Tôi không có nhu cầu tuyển thợ nữa muốn ẩn hoặc xoá tin tuyển dụng để tránh bị spam, gọi điện xin việc từ thợ, tôi phải làm thế nào?',
                  answer : <div className='answer-item'>
                        <div className="step">
                              <p className="title-step">
                                    Để ẩn tin tuyển dụng bạn cần vào danh mục "Tin tuyển dụng thợ nail của tôi" > chọn tin bạn muốn ẩn > nhấn Ẩn tin.
                              </p>
                        </div>
                        <div className="note">
                              <p>(Lưu ý: Bạn có thể hiển thị lại tin sau khi ẩn bằng cách nhấn "Hiện tin" )  </p>
                        </div>
                        <div className="image one">
                              <div>
                                    <img src="assets/images/q-18/1.png" alt=""/>
                              </div>
                        </div>

                        <div className="step">
                              <p className="title-step">
                                    Để xoá tin tuyển dụng bạn vào danh mục "Tin tuyển dụng thợ nail của tôi" > tiếp đó chọn vào tin bạn muốn xoá > nhấn chọn "Xoá" > Xác nhận một lần nữa bạn có chắc chắn muốn xoá không.
                              </p>
                        </div>
                        <div className="note">
                              <p>(Lưu ý: Một khi xác nhận xoá vĩnh viễn thì bạn sẽ không thể khôi phục lại dữ liệu đã xoá )</p>
                        </div>
                        <div className="image one">
                              <div>
                                    <img src="assets/images/q-18/2.png" alt=""/>
                              </div>
                        </div>
                  </div>
            },
            {
                  _id : 19,
                  question : 'Tôi đã đăng tin tuyển dụng và muốn chia sẻ nó với mọi người có được không? Tôi phải làm thế nào ?',
                  answer : <div className='answer-item'>
                        <p className="desc">
                              Ứng dụng có chức năng giúp chủ tiệm có thể chia sẻ tin đăng tuyển dụng của mình đến mọi người để tăng thêm cơ hội tìm kiếm thợ.  Để chia sẻ tin, chủ tiệm vào mục <span>"Tin tuyển dụng thợ nails của tôi"</span>, nhấn chọn vào tin mà bạn muốn chia sẽ > Nhấn nút <span>"Chia sẻ"</span> > Chọn nền tảng muốn chia sẻ.
                        </p>
                        <div className="image one">
                              <div>
                                    <img src="assets/images/q-19/1.png" alt=""/>
                              </div>
                        </div>
                        <div className="note">
                              <p>(Lưu ý: Thông tin được chia sẻ chỉ hiển thị một số nội dung cơ bản về tin tuyển dụng, để xem đầy đủ hơn về thông tin salon, Việt Nails khuyến khích người xem tải ứng dụng để xem chi tiết )</p>
                        </div>
                  </div>
            },
            {
                  _id : 20,
                  question : 'Tôi muốn tìm thợ thì vào đâu để xem ?',
                  answer : <div className='answer-item'>
                        <p className="desc">
                              Vào mục <span>"Danh sách thợ nails đang tìm việc"</span>, tại đây sẽ hiển thị tất cả thông tin thợ đang cần tìm việc làm.
                        </p>
                        <div className="image two">
                              <div>
                                    <img src="assets/images/q-20/1.png" alt=""/>
                              </div>
                              <div>
                                    <img src="assets/images/q-20/2.png" alt=""/>
                              </div>
                        </div>
                        <div className="image one">
                              <div>
                                    <img src="assets/images/q-20/3.png" alt=""/>
                              </div>
                        </div>
                        <div className="note">
                              <p>(Lưu ý: Bạn có thể dùng bộ lọc để tìm thợ trong khu vực mình mong muốn, bằng cách nhấn vào biểu tượng "☰" ở phía góc phải của ô tìm theo tên , sau đó chọn tiểu bang và thành phố mình muốn)</p>
                        </div>
                  </div>
            },
            {
                  _id : 21,
                  question : 'Mật khẩu chưa bảo mật, tôi muốn đổi mật khẩu phải vào đâu?',
                  answer : <div className='answer-item'>
                        <p className="desc">
                              Để đổi mật khẩu, chủ tiệm vào mục <span>"Tài khoản"</span> góc phải màn hình chính > nhấn chọn <span>"Đổi mật khẩu"</span> > điền thông tin vào các trường <span>"Mật khẩu cũ"</span>, <span>"mật khẩu mới"</span> (khách hàng tự điền), nhập lại một lần nữa mật khẩu mới ở trường <span>"Nhập lại mật khẩu mới"</span> sau đó nhấn Lưu.
                        </p>
                        <div className="image two">
                              <div>
                                    <img src="assets/images/q-21/1.png" alt=""/>
                              </div>
                              <div>
                                    <img src="assets/images/q-21/2.png" alt=""/>
                              </div>
                        </div>
                        <div className="image one">
                              <div>
                                    <img src="assets/images/q-21/3.png" alt=""/>
                              </div>
                        </div>
                        <div className="note">
                              <p>(Lưu ý: nếu chủ tiệm quên mật khẩu cũ, vui lòng đăng xuất > sau đó ở màn hình đăng nhập hãy nhấn vào nút "Quên mật khẩu? Nhấn vào đây" > tiếp theo làm theo hướng dẫn trên ứng dụng để được cấp mật khẩu mới. )</p>
                        </div>
                  </div>
            },
            {
                  _id : 22,
                  question : 'Chức năng "Thống kê thu nhập" dùng để làm gì',
                  answer : <div className='answer-item'>
                        <p className="desc">
                              Thống kê thu nhập là chức năng giúp chủ tiệm thống kê toàn bộ số lượng booking, và tổng thu nhập cho tất cả cá booking đã hoàn tất của cửa tiệm.
                        </p>
                        <div className="image two">
                              <div>
                                    <img src="assets/images/q-22/1.png" alt=""/>
                              </div>
                              <div>
                                    <img src="assets/images/q-22/2.png" alt=""/>
                              </div>
                        </div>
                        <div className="image one">
                              <div>
                                    <img src="assets/images/q-22/3.png" alt=""/>
                              </div>
                        </div>
                        <div className="note">
                              <p>(Lưu ý: Để thống kê số lượng booking và thu nhập theo từng thời gian, từng khách hàng, từng thợ > chủ tiệm hãy nhấn vào biểu tượng phểu lọc "☰" ở ô tìm kiếm , sau đó lọc theo nhu cầu )</p>
                        </div>
                  </div>
            },
            {
                  _id : 23,
                  question : 'Khi có thắc mắc hoặc gặp lỗi trong quá trình sử dụng, tôi có thể liên hệ với Việt Nails App qua đâu ?',
                  answer : <div className='answer-item'>
                        <div className="note">
                              <p>Bạn có thể liên hệ với Việt Nails qua </p>
                              <p>- Hotline: (518) 805-1844</p>
                              <p>- Hoặc chat trực tiếp trên fanpage: <a target='_blank' href='https://www.facebook.com/123vietnails'>https://www.facebook.com/123vietnails</a></p>
                        </div>
                        <p className="desc">
                              Ngoài là tổng đài hỗ trợ khách hàng, fanpage còn đăng tải các thông tin mới nhất về Việt Nails. Chủ tiệm có thể đăng kí để theo dõi những cập nhật những tin tức cũng như thông báo mới nhất về ứng dụng.
                        </p>
                  </div>
            },
      ])
      const [selectQuestion, setSelectQuestion] = useState(1)
      const [txtSearch, setTxtSearch] = useState('')
      const prevTxtSearch = useRef('')
      const [collapse, setCollapse] = useState(["1"])

      const renderListQuestion = () => {
            return questions.map(item =>
                  <div className="item-question" key={item._id}>
                        <p className={`question-title  ${item._id === selectQuestion ? 'active' : ''}`} onClick={() => {
                              setSelectQuestion(item._id)
                              window.scrollTo({
                                    top: 150,
                                    left: 0,
                                    behavior: "smooth"
                              });
                              setCollapse(null)

                        }}>{item.question}</p>
                  </div>
            )
      }

      // useEffect(() => {
      //       prevTxtSearch.current = txtSearch
      //
      //       if(txtSearch){
      //             let _questions = [...questions]
      //             _questions = _questions.map(el => {
      //                   el.question = el.question.replaceAll(prevTxtSearch.current, <span className="txt-active">${txtSearch}</span>)
      //                   return el
      //             })
      //             setQuestions(_questions)
      //       }
      // }, [txtSearch])


      const renderListQuestionMobile = () => {
            return <Collapse activeKey={collapse} onChange={(key) => setCollapse(key)}>
                  {questions.map(el =>
                        <Collapse.Panel header={el.question} key={el._id.toString()}>
                              {el.answer}
                        </Collapse.Panel>
                  )}
            </Collapse>
      }


      return (
            <React.Fragment>
                  <Header title='Trung tâm hỗ trợ Vietnails' />
                  <div className='wp-help-page'>
                        <div className="head-page">
                              <div className="wp-inner">
                                    <p className="title">CÁCH SỬ DỤNG VIỆT NAILS - CHỦ TIỆM/ THỢ: HƯỚNG DẪN TỪNG BƯỚC</p>
                                    {/*<div className="search-box">*/}
                                    {/*      <Input value={txtSearch} onChange={(e)  => setTxtSearch(e.target.value)} placeholder='Nhập từ khoá để tìm kiếm'/>*/}
                                    {/*</div>*/}
                              </div>

                        </div>
                        <div className="wp-content-page">
                              <div className="wp-inner">
                                    <div className="wp-section-question desktop">
                                          <div className="question-box-head">
                                                <p><span>Câu hỏi thường gặp</span> <span><i className="fa-solid fa-angle-down"></i></span></p>
                                          </div>
                                          <div className="question-box-content">
                                                <div className="left">
                                                      {renderListQuestion()}
                                                </div>
                                                <div className="right">
                                                      {questions.find(el => el._id === selectQuestion).answer}
                                                </div>

                                          </div>
                                    </div>


                                    <div className="wp-section-question mobile">
                                          <div className="question-box-head">
                                                <p><span>Câu hỏi thường gặp</span> <span><i className="fa-solid fa-angle-down"></i></span></p>
                                          </div>
                                          {/*<Collapse  activeKey ={[collapse]} onChange={(key) =>setCollapse(['1']) }>*/}
                                          {/*      <Collapse.Panel header="Câu hỏi thường gặp" key="1">*/}
                                          {/*            <div className="question-box-content">*/}
                                          {/*                  <div className="left">*/}
                                          {/*                        {renderListQuestion()}*/}
                                          {/*                  </div>*/}
                                          {/*            </div>*/}
                                          {/*      </Collapse.Panel>*/}

                                          {/*</Collapse>*/}

                                          {/*<div className="content-question question-box-content  mobile">*/}
                                          {/*      {questions.find(el => el._id === selectQuestion).answer}*/}
                                          {/*</div>*/}

                                          {renderListQuestionMobile()}
                                    </div>
                              </div>

                        </div>
                        {/*<div className="wp-footer-page">*/}
                        {/*      <div className="wp-inner">*/}
                        {/*            <p className="title">Bạn có muốn tìm thêm thông tin gì không?</p>*/}
                        {/*            <div className="search-box">*/}
                        {/*                  <Input prefix={<i className="fa-solid fa-phone-volume"></i>} />*/}
                        {/*                  <Input prefix={<i className="fa-solid fa-envelope"></i>}/>*/}
                        {/*            </div>*/}
                        {/*      </div>*/}
                        {/*</div>*/}
                        {/*<div className="wp-copy-right">*/}
                        {/*      <i className="fa-brands fa-facebook-f"></i>*/}
                        {/*      <i className="fa-brands fa-instagram"></i>*/}
                        {/*</div>*/}
                  </div>
            </React.Fragment>

      );
}

export default HelpPage;