import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

function TabItem({images}) {
      const responsive = {
            desktop: {
                  breakpoint: { max: 3000, min: 1024 },
                  items: 5,
                  slidesToSlide: 3 // optional, default to 1.
            },
            tablet: {
                  breakpoint: { max: 1024, min: 464 },
                  items: 4,
                  slidesToSlide: 2 // optional, default to 1.
            },
            mobile: {
                  breakpoint: { max: 464, min: 0 },
                  items: 2,
                  slidesToSlide: 1 // optional, default to 1.
            }
      };
      return (
            <Carousel
                  swipeable={false}
                  draggable={false}
                  showDots={false}
                  responsive={responsive}
                  // ssr={true} // means to render carousel on server-side.
                  infinite={true}
                  autoPlay={true}
                  autoPlaySpeed={1000}
                  keyBoardControl={true}
                  customTransition="all .5"
                  transitionDuration={500}
                  containerClass="carousel-container"
                  removeArrowOnDeviceType={["tablet", "mobile"]}
                  dotListClass="custom-dot-list-style"
                  itemClass="carousel-item-padding-40-px"
            >
                  {
                        images.map((item, index) =>
                              <div key={index}>
                                    <img src={item.src} alt=""/>
                              </div>
                        )
                  }
            </Carousel>
      );
}

export default TabItem;