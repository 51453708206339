import React from 'react';
import {Tabs} from "antd";

function StepSection(props) {
      const stepCustomerBooking = [
            {
                  id: 1,
                  title: 'Khách hàng tìm và chọn salon',
                  description: <div>
                        <p>Việt Nails thống kê tất cả các salon đối tác để khách hàng chọn.</p>
                        <p>Khách hàng sẽ tham khảo các salon với đầy đủ thông tin như địa chỉ, thông tin giới thiệu, hình ảnh, feedback (mọi feedback đều rất trung thât), giá tiền, và chọn salon theo sở thích cá nhân</p>
                        <p>Chủ salon có thể hướng dẫn khách hàng của salon mình tìm kiếm salon của họ để đặt lịch hẹn trước tại nhà.
                        </p>
                        <p>Ngoài ra bạn cũng có thể search trên danh sách salon của hệ thống và chọn tiệm nail bạn thường làm.</p>
                  </div>
            },
            {
                  id: 2,
                  title: 'Khách hàng chọn dịch vụ và chọn thời gian muốn làm',
                  description: <div>
                        <p>Việt Nails liệt kê tất cả dịch vụ có tại salon, thời gian hoạt động của salon để khách hàng dễ dàng đặt lịch. (Mẹo: Khách hàng nên đặt lịch cách hiện tại 30 phút để salon có thể chuẩn bị phục vụ khách hàng tốt hơn.)
                        </p>
                  </div>
            },
            {
                  id: 3,
                  title: 'Khách hàng chọn thợ mình yêu thích',
                  description: <div>
                        <p>Nếu khách hàng chưa thể đưa ra lựa chọn hãy nhấn chọn "Anyone Available", chủ salon nail sẽ chọn thợ  phù hợp để phục vụ khách hàng. </p>
                  </div>
            },
            {
                  id: 4,
                  title: 'Khách hàng đặt lịch hẹn và đợi chủ salon xác nhận lịch hẹn',
                  description: <div>
                        <p>Khi chủ salon chấp nhận lịch hẹn, khách hàng sẽ nhận được thông báo. Và hệ thống cũng sẽ tự động thông báo cho khách hàng đến làm dịch vụ khi gần đến giờ hẹn.  (Lưu ý: Khách hàng cần đăng nhập tài khoản mới có thể đặt lịch hẹn. Nếu chưa có tài khoản, có thể đăng kí dễ dàng trong 1 phút)</p>
                  </div>
            },
            {
                  id: 5,
                  title: 'Khách hàng đến salon làm dịch vụ',
                  description: <div>
                        <p>Sau khi làm xong dịch vụ tại salon, khách hàng sẽ nhận được thông báo tổng giá tiền phải trả khi làm dịch vụ và cho feedback trải nghiệm dịch vụ của họ khi làm dịch vụ tại salon</p>
                  </div>
            },
            {
                  id: 6,
                  title: 'Khách hàng có thể xem lại toàn bộ lịch sử lịch hẹn và dịch vụ khách hàng đã làm',
                  description: <div>
                        <p>Vào "Appoinments" trên thanh menu để xem lại, tại đây khách hàng có thể huỷ appoinment nếu chưa được xác nhận, được xem giá tiền dịch vụ, thời gian hẹn, xem tiến độ phục vụ, và thông tin khác. ) </p>
                  </div>
            }
      ]
      const stepCheckIn = [
            {
                  id: 1,
                  title: 'Chủ salon nail đăng nhập sẵn tài khoản của chủ salon trên Tablet / iPad / Phone',
                  description: <div>
                        <p> Chủ salon nail nhấn vào "Check In".
                        </p>
                  </div>
            },
            {
                  id: 2,
                  title: 'Khách hàng đến salon và booking trên thiết bị điện tử',
                  description: <div>
                        <p>Chủ salon đưa Tablet / iPad / Phone cho khách để khách hàng nhập thông tin (gồm: số điện thoại, tên, ngày sinh, email, địa chỉ) và tiến hành booking dịch vụ.</p>
                  </div>
            },
            {
                  id: 3,
                  title: 'Booking dịch vụ.',
                  description: <div>
                        <p>Khách hàng xem danh sách dịch vụ, danh sách staff đang rảnh tại salon và booking dịch vụ và staff theo mong muốn và nhấn booking </p>
                  </div>
            },
            {
                  id: 4,
                  title: 'Salon tiến hành phục vụ',
                  description: <div>
                        <p>Sau khi làm xong dịch vụ tại salon, khách hàng sẽ nhận được thông báo tổng giá tiền phải trả khi làm dịch vụ và cho feedback trải nghiệm dịch vụ của họ khi làm dịch vụ tại salon.</p>
                  </div>
            },
            {
                  id: 5,
                  title: 'Khách hàng có thể xem lại lịch sử lịch hẹn và dịch vụ khách hàng đã làm',
                  description: <div>
                        <p>Vào "Appoinments" trên thanh menu để xem lại, tại đây khách hàng có thể huỷ appoinment nếu chưa được xác nhận, được xem giá tiền dịch vụ, thời gian hẹn, xem tiến độ phục vụ, và thông tin khác. )
                        </p>
                  </div>
            }
      ]

      const renderStepCustomerBooking = () => {
            return stepCustomerBooking.map((item, index)  =>
                  <li key={item.id}>
                        <h5><span>Bước {index + 1}</span> {item.title}</h5>
                        <div className="description">
                              {item.description}
                        </div>
                  </li>
            )
      }

      const renderStepCheckIn = () => {
            return stepCheckIn.map((item, index)  =>
                  <li key={item.id}>
                        <h5><span>Bước {index + 1}</span> {item.title}</h5>
                        <div className="description">
                              {item.description}
                        </div>
                  </li>
            )
      }
      return (
            <section className='step-section'>
                  <div className="circle second"></div>
                  <svg id="wave" style={{transform: 'rotate(0deg)', transition: '0.3s'}} viewBox="0 0 1440 150" version="1.1" xmlns="http://www.w3.org/2000/svg"><defs><linearGradient id="sw-gradient-0" x1={0} x2={0} y1={1} y2={0}><stop stopColor="rgba(190, 67, 195, 1)" offset="0%" /><stop stopColor="rgba(190, 67, 195, 1)" offset="100%" /></linearGradient></defs><path style={{transform: 'translate(0, 0px)', opacity: 1}} fill="url(#sw-gradient-0)" d="M0,135L60,115C120,95,240,55,360,42.5C480,30,600,45,720,57.5C840,70,960,80,1080,77.5C1200,75,1320,60,1440,47.5C1560,35,1680,25,1800,25C1920,25,2040,35,2160,35C2280,35,2400,25,2520,20C2640,15,2760,15,2880,22.5C3000,30,3120,45,3240,52.5C3360,60,3480,60,3600,60C3720,60,3840,60,3960,65C4080,70,4200,80,4320,70C4440,60,4560,30,4680,37.5C4800,45,4920,90,5040,100C5160,110,5280,85,5400,65C5520,45,5640,30,5760,27.5C5880,25,6000,35,6120,37.5C6240,40,6360,35,6480,30C6600,25,6720,20,6840,20C6960,20,7080,25,7200,27.5C7320,30,7440,30,7560,35C7680,40,7800,50,7920,62.5C8040,75,8160,90,8280,85C8400,80,8520,55,8580,42.5L8640,30L8640,150L8580,150C8520,150,8400,150,8280,150C8160,150,8040,150,7920,150C7800,150,7680,150,7560,150C7440,150,7320,150,7200,150C7080,150,6960,150,6840,150C6720,150,6600,150,6480,150C6360,150,6240,150,6120,150C6000,150,5880,150,5760,150C5640,150,5520,150,5400,150C5280,150,5160,150,5040,150C4920,150,4800,150,4680,150C4560,150,4440,150,4320,150C4200,150,4080,150,3960,150C3840,150,3720,150,3600,150C3480,150,3360,150,3240,150C3120,150,3000,150,2880,150C2760,150,2640,150,2520,150C2400,150,2280,150,2160,150C2040,150,1920,150,1800,150C1680,150,1560,150,1440,150C1320,150,1200,150,1080,150C960,150,840,150,720,150C600,150,480,150,360,150C240,150,120,150,60,150L0,150Z" /></svg>
                  <div className="wp-inner">
                       <h4 className="title-section white">
                             Get started with just a few steps
                       </h4>

                       <Tabs
                             defaultActiveKey="1"
                             items={[
                                   {
                                         label: 'Khách hàng booking trước tại nhà',
                                         key: '1',
                                         children: (
                                               <ul className='tab-item'>
                                                     {renderStepCustomerBooking()}
                                               </ul>
                                         ),
                                   },
                                   {
                                         label: 'Check-in tại salon',
                                         key: '2',
                                         children: (
                                               <ul className='tab-item'>
                                                     {renderStepCheckIn()}
                                               </ul>
                                         ),
                                   }
                             ]}
                       />
                 </div>
            </section>
      );
}

export default StepSection;