import React, {useState} from 'react';
import { CaretRightOutlined } from '@ant-design/icons';
import {Collapse} from "antd";

function QuestionSection(props) {
      const question = [
            {
                  id : 1,
                  title: 'Phần mềm Việt Nails nổi bật hơn so với quản lí kiểu cũ là gì?  ',
                  content: <div>
                        <p>Quản lý nhanh chóng một cách thuận tiện chính xác do đồng bộ hóa tại một nơi, dễ dàng xuất dữ liệu báo cáo doanh thu cho doanh nghiệp kinh doanh nails
                        </p>
                        <p>Chi phí vận hành và quản lý được tiết kiệm nhờ tích hợp nhiều tính năng hỗ trợ chủ doanh nghiệp trong cùng một phần mềm như quản lý chi tiêu, quản lý kho, quản lý lịch hẹn</p>
                        <p>Theo dõi và phân quyền nhân viên làm việc từ xa một cách nhanh chóng trên phần mềm quản lý nails chuyên nghiệp Việt Nails.
                        </p>
                        <p>
                              Quản lý thông tin khách hàng, sản phẩm, thu chi không giới hạn số lượng
                        </p>
                  </div>
            },
            {
                  id : 2,
                  title: 'Có nên sử dụng phần mềm quản lí cửa hàng nails Việt Nails không?',
                  content: <div>
                        <p>Nhóm ngành nghề chăm sóc sắc đẹp là trọng tâm phát triển của phần mềm quản lý salon nails Việt Nails. Xây dựng các tính năng phù hợp nhất để hỗ trợ chủ doanh nghiệp trong quá trình vận hành và quản lý của mình</p>
                        <p>Quản lý vận hành khép kín và xây dựng thương hiệu riêng biệt, tìm kiếm khách hàng salon tiềm năng cho salon trên web booking online, giúp đẩy mạnh doanh thu cho salon.</p>
                        <p>Hỗ trợ giải đáp thắc mắc về phần mềm và hướng dẫn nhân viên sử dụng phần mềm quản lý salon nails.</p>
                  </div>
            },
            {
                  id : 3,
                  title: 'Phần mềm quản lý salon nails Việt Nails dễ dàng thao tác và sử dụng không?',
                  content: <div>
                        <p>Luôn sẵn sàng hỗ trợ chủ salon tối đa trong vận hành quản lý. Phần mềm quản lý salon nails Việt Nails luôn nâng cấp thường xuyên để phù hợp với yêu cầu của các chủ doanh nghiệp. Giao diện và trải nghiệm người dùng được xem xét cẩn thận và điều chỉnh để tối ưu nhất.</p>
                        <p>Hỗ trợ tư vấn 24/7, đội ngũ tư vấn viên chuyên nghiệp giúp giải đáp mọi vấn đề và thắc mắc của khách hàng khi sử dụng</p>
                  </div>
            },
            {
                  id : 4,
                  title: 'Quy trình đăng kí sử dụng phần mềm quản lí cửa hàng nails Việt Nails như thế nào?',
                  content: <div>
                        <p>Bước 1: Đăng ký nhận tư vấn tại đây với đầy đủ thông tin: họ tên, số điện thoại, email, tên doanh nghiệp,..
                        </p>
                        <p>Bước 2: Khách hàng sẽ được tư vấn gói phần mềm quản lý salon nails chuyên nghiệp bởi đội ngũ chăm sóc khách hàng của Việt Nails, đảm bảo giải quyết các vấn đề của doanh nghiệp hiệu quả theo nhu cầu của doanh nghiệp.
                        </p>
                        <p>Bước 3: Việt Nails sẽ tạo tài khoản demo để khách hàng dùng thử, trải nghiệm các tính năng có trong phần mềm hệ thống.</p>
                        <p>Bước 4: Ký kết hợp đồng để sử dụng phần mềm cho salon nails Việt Nails.
                        </p>
                        <p>Bước 5: Việt Nails sẽ hỗ trợ cài đặt phần mềm tại các địa điểm mà chủ salon nails yêu cầu trong hợp đồng. Hỗ trợ hướng dẫn và training nhân viên sử dụng phần mềm. Trong quá trình sử dụng phần mềm nếu chủ doanh nghiệp có vấn đề gì cần hỗ trợ có thể liên hệ với đội ngũ hỗ trợ kỹ thuật của Việt Nails tại:
                              <p>Email: support@Việt Nails.vn</p>
                              <p>Hoặc Messenger (Facebook) chăm sóc khách hàng</p>
                        </p>
                  </div>
            },
            {
                  id : 5,
                  title: 'Chính sách bảo hành của Việt Nails như thế nào?',
                  content: <div>
                        <p>Bảo hành xuyên suốt trong thời gian chủ doanh nghiệp sử dụng phần mềm quản lý salon nails Việt Nails. Nếu có lỗi phát sinh, Việt Nails sẽ khác phục lỗi hoàn toàn miễn phí cho doanh nghiệp làm đẹp. Bên cạnh đó, Việt Nails còn cập nhật thêm các tính năng phần mềm thường xuyên để đáp ứng nhu cầu hiện nay.
                        </p>
                  </div>
            }
      ]

      const [tab, setTab] = useState(question.length > 0 ? question[0].id : 1)

      const renderQuestion = () => {
            return question.map(item =>
                  <Collapse.Panel header={item.title} key={item.id}>
                        <div>{item.content}</div>
                  </Collapse.Panel>
            )
      }

      const onChange = (key) => {
            setTab(key)
      }
      return (
            <section className="question-section">
                  <div className="wp-inner">
                        <h4 className="title-section">
                              Phần mềm quản lí salon nails Việt Nails và các câu hỏi thường gặp
                        </h4>
                        <div>
                              <Collapse
                                    activeKey={tab}
                                    onChange={onChange}
                                    className='list-questions'
                                    bordered={false}
                                    expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                                    expandIconPosition={"end"}
                              >
                                    {renderQuestion()}
                              </Collapse>
                        </div>
                  </div>
            </section>
      );
}

export default QuestionSection;