import React from 'react';
import {Tabs} from "antd";
import tabFirst from '../../../../assets/images/utility/utility-1.png'
import tabTwo from '../../../../assets/images/utility/utility-2.png'
import tabThree from '../../../../assets/images/utility/utility-3.png'

function UtilitySection(props) {
      const blockFirst = [
            'Hỗ trợ đặt hẹn online cho khách hàng, tiết kiệm thời gian và chi phí di chuyển',
            'Nhắc lịch hẹn cho khách hàng và nhân viên để đảm bảo được phục vụ tốt nhất\n',
            'Tình trạng đặt lịch của quý khách luôn được cập nhật chuẩn xác (chưa đến, hủy lịch, thay đổi lịch…)',
            'Khách hàng đặt hẹn nhanh chóng chưa tới 1 phút.'
      ]

      const blockTwo = [
            'Phần mềm spa giúp thống kê lịch hẹn rõ ràng, linh hoạt cho thấy sự chủ động và chuyên nghiệp của dịch vụ spa. Vì thế, đây là tính năng cần thiết và “ghi điểm” trong mắt\n' +
            'khách hàng, đồng thời tiết kiệm thời gian và đảm bảo tính chính xác cao.',
            'Quản lý đặt lịch hẹn nhanh, chính xác, rõ ràng cho khách hàng theo ngày, tháng, năm',
            'Linh hoạt trong việc thay đổi lịch hẹn, giúp nhân viên Theo dõi chi tiết lịch hẹn khách.',
            'Gợi ý phân công nhân viên phục vụ khách hàng theo thời gian làm việc trống',
            'Cập nhật chính xác trạng thái của khách hàng (đã check-in, đổi lịch hẹn, đang chờ, hoàn thành)',
            'Theo dõi lịch trình, nhắc hẹn, đảm bảo nâng cao chất lượng dịch vụ',
            'Khắc phục tình trạng nhân viên bị quá tải trong các dịp đông khách như cuối tuần, ngày lễ, cận Tết… với tính năng đặt hẹn online của phần mềm.',
            'Quản lý lịch hẹn làm móng, nối mi của khách tại một nơi trên hệ thống, hạn chế tình trạng không có nhân viên chăm sóc khách hàng.',
            'Phân công nhân viên nails để chăm sóc khách hàng trong khung làm việc trống.',
            'Tùy chỉnh dễ dàng lịch hẹn khách làm dịch vụ, Theo dõi thông tin tại một nơi để không bỏ lỡ giờ hẹn',
      ]

      const blockThree = [
            'Hỗ trợ tính năng đánh giá dịch online cho khách hàng, giúp doanh nghiệp có được những đánh giá chân thật nhất.Tối ưu trải nghiệm khách hàng thông qua đánh giá dịch vụ.\n',
            'Quản lý được các phản hồi từ khách hàng trên các kênh khác nhau, giúp spa tối ưu hiệu quả PR, Marketing cho dịch vụgia tăng nhận diện thương hiệu cho chuỗi hệ thống salon nails.',
      ]

      const renderBlockFirst = () => {
            return blockFirst.map((item, index) =>
                  <li key={index}><span>{index + 1}</span>{item}
                  </li>
            )
      }

      const renderBlockTwo = () => {
            return blockTwo.map((item, index) =>
                  <li key={index}><span>{index + 1}</span>{item}
                  </li>
            )
      }

      const renderBlockThree = () => {
            return blockThree.map((item, index) =>
                  <li key={index}><span>{index + 1}</span>{item}
                  </li>
            )
      }
      return (
            <section className='utility-section'>
                  <div className="wp-inner">
                        <h4 className="title-section">
                              Nâng cao chất lượng dịch vụ chăm sóc khách hàng
                        </h4>
                        <div>
                              <Tabs
                                    defaultActiveKey="1"
                                    items={[
                                          {
                                                label: (
                                                      <div>
                                                            <div className="thumb">
                                                                  <img src={tabFirst} alt=""/>
                                                            </div>
                                                            <p className='title-tab'>Đặt hẹn trực tuyến dễ dàng</p>
                                                      </div>
                                                ),
                                                key: '1',
                                                children: (
                                                      <ul>
                                                            {renderBlockFirst()}
                                                      </ul>
                                                ),
                                          },
                                          {
                                                label: (
                                                      <div>
                                                            <div className="thumb">
                                                                  <img src={tabTwo} alt=""/>
                                                            </div>

                                                            <p className='title-tab'>Quản lí lịch hẹn khách hàng hiệu quả</p>
                                                      </div>
                                                ),
                                                key: '2',
                                                children: (
                                                      <ul>
                                                            {renderBlockTwo()}
                                                      </ul>
                                                ),
                                          },
                                          {
                                                label: (
                                                      <div>
                                                            <div className="thumb">
                                                                  <img src={tabThree} alt=""/>
                                                            </div>
                                                            <p className='title-tab'>Đánh giá dịch vụ từ khách hàng</p>
                                                      </div>
                                                ),
                                                key: '3',
                                                children: (
                                                      <ul>
                                                            {renderBlockThree()}
                                                      </ul>
                                                ),
                                          },
                                    ]}
                              />
                        </div>
                  </div>
            </section>
      );
}

export default UtilitySection;