import React from 'react';

function Circle(props) {
      return (
            <React.Fragment>
                  <div className='circle one second'></div>
                  <div className='circle two main'></div>
                  <div className='circle three main'></div>
                  <div className='circle four second'></div>
            </React.Fragment>
      );
}

export default Circle;