import React from 'react';
import Header from "./header";
import Footer from "./footer";
import Copyright from "./copyright";
import {Button} from "antd";
import {useNavigate} from "react-router-dom";

function Master(props) {
      const navigate = useNavigate()
      return (
            <div className='wp-wrapper'>
                  <div className="btn-fixed">
                        <Button onClick={() => navigate('/instruction')}>Hướng dẫn dùng ứng dụng</Button>
                  </div>
                  <Header/>
                  <div className='content'>
                        {props.children}
                  </div>
                  <Footer/>
                  <Copyright/>
            </div>
      );
}

export default Master;