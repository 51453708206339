import React from 'react';
import {Button, Carousel } from "antd";
import imageBannerFirst from '../../../../assets/images/banner-top/1.png'
import imageBannerTwo from '../../../../assets/images/banner-top/2.png'
import imageBannerThree from '../../../../assets/images/banner-top/3.png'
import Circle from "./circle";

function BannerTopSection(props) {
      return (
            <section className='banner-top-section'>
                  <Carousel autoplay={true}>
                        <div className='slider-item'>
                              <div className="d-flex">
                                    <div className="text">
                                          <h3>Thay đổi nhỏ - Hiệu quả lớn</h3>

                                          <ul>
                                                <li>Phần mềm quản lí salon nails online hiện đại nhất</li>
                                                <li>Đơn giản và dễ dàng sử dụng</li>
                                                <li>Quản lí từ xa, báo cáo và thống kê đa nền tảng</li>
                                                <li>Tích hợp nhiều tính năng giúp chủ salon quản lí nail hiệu quả</li>
                                          </ul>

                                          <div className="description">
                                                <p>Gia tăng đơn đặt chỗ và quản lí salon của bạn với Việt Nails</p>
                                                <p>Hãy liên lạc và bắt đầu phát triển doanh nghiệp của bạn, miễn phí tham gia không cần yêu cầu đặt cọc hoặc thẻ tín dụng. Vui lòng điền các thông tin cần thiết và các chuyên gia của chúng tôi sẽ liên lạc với bạn.</p>
                                          </div>

                                          <div className="wp-btn">
                                                <Button>Tải ứng dụng ngay</Button>
                                          </div>
                                    </div>
                                    <div className="thumb">
                                          <img src={imageBannerFirst} alt=""/>
                                          <Circle/>
                                    </div>
                              </div>
                        </div>
                        <div className='slider-item'>
                              <div className="d-flex">
                                    <div className="text">
                                          <h3>Đơn Giản - Tiện Lợi</h3>

                                          <ul>
                                                <li>Phần Mềm đặt lịch làm nail Online.</li>
                                                <li>Hơn 100 salon nails tại US cho bạn lựa chọn</li>
                                                <li>Đầy đủ thông tin về các salon nail.</li>
                                                <li>Thông tin đánh giá thật tế về salon nail, không sợ thông tin ảo hay sai sự thật</li>
                                                <li>Thiết kế đơn giản dễ thao tác.</li>
                                                <li>Người dùng dễ dàng tra cứu lạ</li>
                                                <li>Người dùng dễ dàng tra cứu lại lịch sử sử dụng dịch vụ cũng như gửi đánh giá về dịch vụ cũng như nhân viện của salon ngay trên ứng dụng.</li>
                                                <li>Ứng dụng cho phép salon nails gửi các thông báo khuyến mãi, tin mới đến các khách hàng</li>
                                          </ul>

                                          <div className="wp-btn">
                                                <Button>Tải ứng dụng đặt lịch hẹn làm nails</Button>
                                          </div>
                                    </div>
                                    <div className="thumb">
                                          <img src={imageBannerTwo} alt=""/>
                                          <Circle/>
                                    </div>
                              </div>
                        </div>
                        <div className='slider-item'>
                              <div className="d-flex">
                                    <div className="text">
                                          <h3>KẾT NỐI HIỆU QUẢ - AN TOÀN</h3>

                                          <ul>
                                                <li>Phần Mềm Đăng tin rao vặt trực tuyến miễn phí .</li>
                                                <li>Đăng tin tuyển dụng, mua bán salon, rao vặt, …</li>
                                                <li>Xem tin tuyển dụng và nhận việc làm trực tiếp trên ứng dụng.</li>
                                                <li>Tìm và đặt thợ trực tiếp trên ứng dụng cho salon của bạn</li>
                                          </ul>

                                          <div className="wp-btn">
                                                <Button>Tải ứng dụng đăng tin</Button>
                                          </div>
                                    </div>
                                    <div className="thumb">
                                          <img src={imageBannerThree} alt=""/>
                                          <Circle/>
                                    </div>
                              </div>
                        </div>
                  </Carousel>
            </section>
      );
}

export default BannerTopSection;