import React from 'react';
import {Tabs} from "antd";
import image1 from '../../../../assets/images/salon/1.png'
import image2 from '../../../../assets/images/salon/2.png'
import image3 from '../../../../assets/images/salon/3.png'
import image4 from '../../../../assets/images/salon/4.png'
import TabItem from "./tabItem";
import Circle from "./circle";


function GallerySection(props) {
      const galleryData = [
            {
                  key: 1,
                  title: 'Booking',
                  images : [
                        {
                              id: 1,
                              src: image1,
                        },
                        {
                              id: 2,
                              src: image2,
                        },
                        {
                              id: 3,
                              src: image3,
                        },
                        {
                              id: 4,
                              src: image4,
                        }
                  ]
            },
            {
                  key: 2,
                  title: 'Manage Salon',
                  images : [
                        {
                              id: 1,
                              src: image1,
                        },
                        {
                              id: 2,
                              src: image2,
                        },
                        {
                              id: 3,
                              src: image3,
                        },
                        {
                              id: 4,
                              src: image4,
                        }
                  ]
            },
            {
                  key: 3,
                  title: 'Post Ads',
                  images : [
                        {
                              id: 1,
                              src: image1,
                        },
                        {
                              id: 2,
                              src: image2,
                        },
                        {
                              id: 3,
                              src: image3,
                        },
                        {
                              id: 4,
                              src: image4,
                        }
                  ]
            }
      ]

      const tabData  = []

      galleryData.map(item => {
            return tabData.push({
                  label: item.title,
                  key: item.key,
                  children: <TabItem images={item.images}/>,
            })
      })

      return (
            <section className='gallery-section'>
                  <div className="wp-inner">
                        <Circle/>
                        <h4 className="title-section">
                              Have a look at what's inside the app
                        </h4>
                        <Tabs
                              defaultActiveKey="1"
                              items={tabData}
                        />
                  </div>
            </section>
      );
}

export default GallerySection;