import React from 'react';

function Copyright(props) {
      return (
            <div className='copyright'>
                  <p><span>Copyright 2022 @ Kan-Tek All Rights Reserved.</span>  <span> Design by Kantek</span></p>
            </div>
      );
}

export default Copyright;