import React from 'react';
import {Tabs} from "antd";
import Circle from "./circle";

function FeatureSectionTop(props) {
      const initData = [
            {
                  key : 1,
                  icon: <i className="fa-solid fa-database"></i>,
                  title : 'Quản lí dữ liệu khách hàng',
                  description: [
                        'Phần mềm quản lý salon nails Việt Nails đảm bảo tính bảo mật về thông tin người sử dụng. Giúp chủ doanh nghiệp quản lý thông tin nhanh chóng chính xác,\n' +
                        'từ đó có chính sách để tìm kiếm khách hàng mới và giữ chân khách hàng cũ.',
                        'Cập nhật data khách hàng đầy đủ và chi tiết trên phần mềm hệ thống như họ tên, điện thoại, ngày sinh,…',
                        'Lưu lại không giới hạn hình ảnh mẫu nail của khách hàng sau khi thực hiện, để dễ dàng tạo album tư vấn.',
                        'Thông tin người tiêu dùng được bảo mật nên chủ doanh nghiệp có thể hoàn toàn an tâm.',
                        'Tăng doanh thu dễ dàng khi phân loại các nhóm khách đúng cách (nhóm khách mới, nhóm khách cũ chi tiêu nhiều, ghé cửa hàng nails thường xuyên…)',
                  ]
            },
            {
                  key : 2,
                  icon: <i className="fa-solid fa-users"></i>,
                  title : 'Quản lí nhân lực',
                  description: [
                        'Phân bổ thời gian làm việc hợp lý của nhân viên sẽ tiết kiện đáng kể được chi phí vận hành hằng ngày của salon. Với phần mềm quản lý nhân viên salon nails của Việt Nails hỗ trợ chủ salon vận hành một cách hiệu quả nhất.',
                        'Hệ thống chấm công lưu trữ thời gian checkin và checkout của mỗi nhân viên, thống kế chi tiết tổng số giờ làm chính thức và giờ làm thêm.\n',
                        'Tiết kiệm chi phí quản lý, phân công nhân viên nails làm việc từ xa với phần mềm quản lý salon nails Việt Nails.\n',
                        'Linh hoạt khi phân bổ và điều phối nhân viên kỹ thuật nails giữa các cơ sở khác nhau, lưu lại lịch sử làm việc.',
                  ]
            },
            {
                  key : 3,
                  icon: <i className="fa-solid fa-folder"></i>,
                  title : 'Quản lí tài chính',
                  description: [
                        'Chỉ cần sử dụng phần mềm quản lý cửa hàng nail online, sẽ giải quyết mọi vấn đề liên quan đến sổ sách, thu chi.\n',
                        'Giúp chủ salon quản lý dòng tiền hiệu quả, chính xác nhất theo ngày giờ, nội dung. Xuất báo cáo biểu đồ rõ ràng để theo dõi tình trạng từng chi nhánh.\n',
                        'Kiểm tra lịch sử đơn giản ngay trên phần mềm quản lý salon nails để đối soát chi tiết nếu cần thiết',
                  ]
            },
            {
                  key : 4,
                  icon: <i className="fa-solid fa-chart-line"></i>,
                  title : 'Quản lí kho',
                  description: [
                        'Giúp chủ salon quản lý hình ảnh, thông tin salon hiệu qủa nhằm thu hút khách hàng.\n',
                        'Thiết lập thời gian làm việc linh hoạt, tự động check out nếu nhân viên quên check out khi hết giờ làm việc.',
                        'Quản lý thông tin các dịch vụ, giá tiền dịch vụ của salon.\n',
                  ]
            }
      ]

      const tabData  = []

      initData.map(item => {
            return tabData.push({
                  label: (
                        <div>
                              <span className='tab-icon'>{item.icon}</span>
                              <p className='title-tab'>{item.title}</p>
                        </div>
                  ),
                  key: item.key,
                  children: (
                        <div className='description'>
                              <ul>
                                    {item.description.map((el, index) =>
                                          <li  key={index}><span>{index + 1}</span>{el}</li>
                                    )}
                              </ul>
                        </div>
                  ),
            })
      })

      return (
            <section className='feature-top-section'>
                  <svg id="waves" style={{transform: 'rotate(0deg)', transition: '0.3s'}} viewBox="0 0 1440 130" version="1.1" xmlns="http://www.w3.org/2000/svg"><defs><linearGradient id="sw-gradient-0" x1={0} x2={0} y1={1} y2={0}><stop stopColor="rgba(190, 67, 195, 1)" offset="0%" /><stop stopColor="rgba(190, 67, 195, 1)" offset="100%" /></linearGradient></defs><path style={{transform: 'translate(0, 0px)', opacity: 1}} fill="url(#sw-gradient-0)" d="M0,39L48,52C96,65,192,91,288,95.3C384,100,480,82,576,62.8C672,43,768,22,864,26C960,30,1056,61,1152,78C1248,95,1344,100,1440,101.8C1536,104,1632,104,1728,104C1824,104,1920,104,2016,88.8C2112,74,2208,43,2304,30.3C2400,17,2496,22,2592,34.7C2688,48,2784,69,2880,78C2976,87,3072,82,3168,69.3C3264,56,3360,35,3456,34.7C3552,35,3648,56,3744,73.7C3840,91,3936,104,4032,93.2C4128,82,4224,48,4320,43.3C4416,39,4512,65,4608,62.8C4704,61,4800,30,4896,21.7C4992,13,5088,26,5184,28.2C5280,30,5376,22,5472,30.3C5568,39,5664,65,5760,65C5856,65,5952,39,6048,36.8C6144,35,6240,56,6336,56.3C6432,56,6528,35,6624,41.2C6720,48,6816,82,6864,99.7L6912,117L6912,130L6864,130C6816,130,6720,130,6624,130C6528,130,6432,130,6336,130C6240,130,6144,130,6048,130C5952,130,5856,130,5760,130C5664,130,5568,130,5472,130C5376,130,5280,130,5184,130C5088,130,4992,130,4896,130C4800,130,4704,130,4608,130C4512,130,4416,130,4320,130C4224,130,4128,130,4032,130C3936,130,3840,130,3744,130C3648,130,3552,130,3456,130C3360,130,3264,130,3168,130C3072,130,2976,130,2880,130C2784,130,2688,130,2592,130C2496,130,2400,130,2304,130C2208,130,2112,130,2016,130C1920,130,1824,130,1728,130C1632,130,1536,130,1440,130C1344,130,1248,130,1152,130C1056,130,960,130,864,130C768,130,672,130,576,130C480,130,384,130,288,130C192,130,96,130,48,130L0,130Z" /></svg>
                  <Circle/>
                  <div className="wp-inner">

                        <h4 className="title-section white">
                              Phần mềm quản lí salon nails chuyên nghiệp nhất hiện nay
                        </h4>

                        <Tabs
                              defaultActiveKey="1"
                              items={tabData}
                        />
                  </div>
            </section>
      );
}

export default FeatureSectionTop;